<script>
import Layout from "../../_layouts/main"
import PageHeader from "@/components/page-header"
import Document from '../../../components/api/document'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Document
  },
  data() {
    return {
      title: this.$t('에볼루션 상세내역'),
      apiUrl: process.env.VUE_APP_API_SERVER_URL + 'transaction/evolution',
      response: '',
      body: {
        transactionKey: {type: 'text', value: '', desc: this.$t('text.api.pragmatic_transaction_desc'), required: true}
      }
    };
  },
  mounted() {
    /*
    let host = location.host
    if (host.indexOf('localhost') > -1 || host.indexOf('127.0.0.1') > -1) {
      host = 'http://127.0.0.1:39011/'
    } else {
      host = `https://api.${host}/`
    }

     */
    const host = process.env.VUE_APP_API_SERVER_URL
    this.apiUrl = host + 'transaction/evolution'
    // 응답값 설정
    this.response = '{\n' +
        '  "code": 0, \t\t\t\t\t\t\t\t\t// 응답값(0이 아니면 오류)\n' +
        '  "data": "내역데이터"\n' +
        '}'
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">{{ apiUrl }}</div>
            <div class="card-title-desc">{{ $t('text.api_games_desc') }}</div>
            <hr/>
            <Document :data="body" :apiUrl="apiUrl" :response="response" :require-vendors="false"
                      :require-ip-address="false" :require-request-key="false"/>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
